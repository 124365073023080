@import '~@ergeon/core-components/src/components/variables';

.error-page {
  padding: 0 $h-20;
}

.error-page__img-container {
  max-width: 50%;
  padding-top: $h-20;
  padding-bottom: $h-20;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
}
