@import './tailwind.css';
@import '~@ergeon/core-components/src/components/variables.scss';

.CenteredLayout {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.Auth {
  margin: $v-64 auto 0;
  &-title {
    font-weight: bold;
  }
}

// Tailwind resets looknfeel so this will keep the way it was originally
.Report {
  line-height: $v-24;

  button {
    padding-inline: $v-6;
    padding-block: 1px;
  }
  svg {
    display: unset;
    box-sizing: border-box;
  }
  table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;
  }
  td {
    display: table-cell;
    vertical-align: inherit;
    unicode-bidi: isolate;
  }
}